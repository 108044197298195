<template>
  <div class="container">
    <el-upload
      class="avatar-uploader"
      :action="uploadConf.qiniu_upload"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :on-error="handleError"
      :before-upload="beforeAvatarUpload"
      :data="uploadConf"
    >
      <img v-if="resultUrl" :src="resultUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
export default {
  name: 'QiniuUploader',
  data() {
    return {}
  },

  props: {
    uploadConf: {
      type: Object
    },
    resultUrl: {
      type: String,
      default: ''
    }
  },

  mounted() {},
  methods: {
    beforeAvatarUpload: function(file) {
      this.uploadConf.key = nanoid()
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG && !isPNG) {
        this.$message.error('图片只能是 JPG/PNG 格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!')
        return false
      }
    },
    handleAvatarSuccess: function(res, file) {
      let url = this.uploadConf.domain + '/' + res.key
      this.$emit('update:resultUrl', url)
    },
    handleError: function(res) {
      this.$message.error({
        message: '上传失败',
        duration: 2000,
        type: 'warning'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px !important;
    height: 150px !important;
    line-height: 150px !important;
    text-align: center;
  }
  .avatar {
    width: 150px !important;
    height: 150px !important;
    display: block;
  }
}
</style>
