<template>
  <div>
    <qiniu-upload :resultUrl.sync="resultUrl" v-if="uploadConf != null && type == 'img'" :uploadConf="uploadConf"></qiniu-upload>
    <qiniu-uploader-file
      v-if="uploadConf != null && type == 'file'"
      :fileList.sync="fileList"
      :uploadConf="uploadConf"
     
    ></qiniu-uploader-file>
  </div>
</template>

<script>
import { getUploadConfigApi } from './api'
import QiniuUpload from '@/components/file-upload/components/qiniu-upload.vue'
import QiniuUploaderFile from '@/components/file-upload/components/qiniu-upload-file'

export default {
  name: 'FileUpload',
  components: { QiniuUploaderFile, QiniuUpload },

  props: {
    uploadResult: {
      type: Function
    },
    // fileList: {
    //   type: Array
    // },
    type: {
      type: String,
      default: 'img'
    },
    url: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      uploadType: '',
      uploadConf: null,
      resultUrl: this.url,
      fileList:[]
    }
  },
  watch: {
    resultUrl: {
      handler(newV, oldV) {
        if (newV) {
          this.$emit('update:url', newV)
        }
      }
    },
    url: {
      handler(newV, oldV) {
        if (newV) {
          this.resultUrl = newV
        }
      }
    },
    fileList:{
      handler(newV,oldV){
        if(newV){
          this.$emit('update:files',newV)
        }
      }
    }
  },
  created() {
    getUploadConfigApi().then(res => {
      this.uploadType = res.uploadType
      this.uploadConf = res.config
    })
  },
  mounted() {},
  methods: {}
}
</script>
