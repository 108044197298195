<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="upload-btn">
      <!-- <el-button type="primary" size="mini" @click="showUpload">上传</el-button> -->
    </div>

    <img src="@/assets/org.png" />
    <!-- 弹窗 -->
    <el-dialog title="更新组织结构" :visible.sync="dialogVisible" width="width">
      <el-form ref="form" :model="formData" label-width="80px">
        <el-form-item label="组织架构">
          <FileUpload :url.sync="formData.face"></FileUpload>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FileUpload from '@/components/file-upload/index.vue'
export default {
  name: 'Index',
  components: { FileUpload },
  data() {
    return {
      dialogVisible: false,
      formData: {}
    }
  },

  mounted() {},

  methods: {
    showUpload() {
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
</style>
